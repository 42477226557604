.our-tours-tour-container {
  display: flex;
  flex-direction: row; /* Default to row layout */
  align-items: flex-start;
  margin-bottom: 20px;
  cursor: pointer;
}

.our-tours-tour-container img {
  width: 40%;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.our-tours-tour-details {
  width: 60%;
  padding-left: 20px;
}

.our-tours-tour-details p,
.our-tours-tour-details h2 {
  margin: 0 0 10px 0;
}

.our-tours-tour-details h2 {
  font-size: 1.5em;
}

.our-tours-tour-details p {
  font-size: 1.1em;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .our-tours-tour-container {
    flex-direction: column; /* Change to column layout on small screens */
    align-items: center;
  }

  .our-tours-tour-container img {
    width: 100%; /* Make image full-width */
    margin-bottom: 15px; /* Add space between image and details */
  }

  .our-tours-tour-details {
    width: 100%; /* Make details full-width */
    padding-left: 0; /* Remove left padding */
  }
}