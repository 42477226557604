.home-container {
  margin: 0 auto;
  width: 75%;
  text-align: center
}

@media (max-width: 1024px) {
  .home-container {
    width: 90%;
  }
}

.home-container hr {
  max-width: 5%;
  height: 3px;
  background: black; 
  margin: 0 47.5% 0 47.5%;
}

@media (max-width: 900px) {
  .home-container hr {
    max-width: 10%;
    height: 4px;
    background: black; 
    margin: 0 45% 0 45%;
  }
}

/* SERVICES */

@media (min-width: 768px) {
  .services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

@media (min-width: 1024px) {
  .services {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* END SERVICES */

/* HOME TOUR */

.home-shorter-title {
  max-width: 50%;
  margin: 0 25% 0 25%;
  color: #2e2e2e;
  font-size: 35px;
  text-align: center;
}

@media (max-width: 900px) {
  .home-shorter-title {
    max-width: 100%;
    margin: 0;
    font-size: 30px;
  }
}

.home-tour-column {
  float: left;
  width: 33.33%;
  padding: 1%;
  box-sizing: border-box;
  cursor: pointer;
}

.home-tour-column-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* This maintains the square aspect ratio */
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.home-tour-column img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  object-fit: cover;
  display: block;
}

.home-tour-column p {
  text-align: left;
  margin-top: 3%;
  font-size: 18px;
}

.home-tour-column .home-tour-column-smaller-p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-align: left;
  margin-top: 3%;
  font-size: 15px;
}


.home-tour-column h4 {
  text-align: left;
  margin-top: 3%;
  font-size: 20px;
}

/* Adjust columns for smaller screens */
@media (max-width: 900px) {
  .home-tour-column {
    float: left;
    width: 100%;
    margin-bottom: 4%;
  }
}

.home-blog-post-column-container {
  width: 90%;
  margin: 0 5% 0 5%;
}

.home-blog-post-column {
  width: 31.33%;
  margin: 0 1% 0 1%;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
}

.home-blog-post-column p {
  margin-top: 3%;
}

.home-blog-post-column h3 {
  font-weight: 600;
  margin-top: 1%;
}

@media (max-width: 900px) {
  .home-blog-post-column {
    width: 100%;
  }
}

.square-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.square-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* HOME WELCOME */
.home-welcome-text {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto 1.5rem;
  padding: 0 1rem; /* Add padding for smaller screens */
}

@media screen and (max-width:900px) {

}

.home-welcome-icon-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Use a smaller gap for better alignment on mobile */
  flex-wrap: wrap;
  width: 100%; /* Full width for better spacing on mobile */
  margin: 0 auto;
  text-align: center;
}

.home-welcome-icon-box {
  flex: 1 1 100%; /* Full width by default */
  max-width: 150px; /* Limit width for better alignment */
  margin: 10px auto; /* Center on mobile */
}

@media (min-width: 768px) {
  .home-welcome-icon-box {
    flex: 1 1 calc(33.33% - 20px); /* Use percentage for dynamic sizing */
    max-width: calc(33.33% - 20px); /* Consistent max width */
  }
}

.home-welcome-icon {
  font-size: 50px; /* Slightly smaller icons for mobile */
  color: #2e2e2e;
}

.home-welcome-icon-text {
  font-size: 17px; /* Adjust text size for better readability */
  font-weight: bold;
  margin-top: 0.5rem;
}
/* END HOME WELCOME */