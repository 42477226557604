.slider-container {
  position: relative;
  width: 100%;
}

.custom-nav-button {
  position: absolute;
  top: 40%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.custom-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
  transform: scale(1.1);
}

.prev-button {
  left: -90px;
}

.next-button {
  right: -90px;
}

@media (max-width: 1024px) {
  .custom-nav-button {
    display: none;
  }
}

.custom-nav-button:active {
  transform: scale(1);
}

.custom-nav-button:focus {
  outline: none;
}

.slider-tours-p {
  text-align: left;
  margin-top: 3%;
  font-size: 16px;
}

.slider-tours-h4 {
  text-align: left;
  margin-top: 2%;
  font-size: 17px;
  font-weight: 600;
}

.slider-tour-column-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 10px;
  border: solid 1px #ccc;
}

.slider-tours-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
