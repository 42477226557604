.trip-book-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.trip-book-header-column-left img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.trip-book-header-column-right {
  float: left;
  width: 50%;
}

.trip-book-small-image-header {
  display: none;
}

@media screen and (max-width:900px) {
  .trip-book-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
  }

  .trip-book-small-image-header {
    display: block;
  }

  .trip-book-large-image-header {
    display: none;
  }
}

.trip-book-column-desc-left {
  float: left;
  width: 100%;
}

.trip-book-price-box {
  margin-left: 12%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5%;
  border-radius: 10px;
  border: 1px solid #ccc;
}

@media screen and (max-width:900px) {
  .trip-book-column-desc-left {
    width: 100%;
  }
  
  .trip-book-column-desc-right {
    display: none;
  }
}