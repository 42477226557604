.basket-left-column {
  float: left;
  width: 65%;
}

.basket-right-column {
  float: left;
  width: 35%;
}

@media screen and (max-width:900px) {
  .basket-left-column {
    width: 100%;
  }
  
  .basket-right-column {
    display: none;
  }
}

.basket-item {
  width: 100%;
  padding: 2%;
  border-radius: 10px;
  text-align: left;
}

.basket-item-title {
  display: flex;
  align-items: center;
}

.basket-item img {
  margin: 0 4% 0 0;
  border-radius: 10px;
  width: 20%;
  aspect-ratio: 1;
  object-fit: cover;
}

.basket-item p {
  text-align: left;
}

.basket-delete-button {
  padding: 1% 2% 1% 2%;
  font-size: 18px;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
  background-color: #ececec;
}

.basket-delete-button:hover {
  background-color: rgb(226, 226, 226);
}

.basket-checkout-box {
  margin-left: 12%;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  padding: 5%;
  border-radius: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.basket-only-small-screen {
  display: none;
}

@media screen and (max-width:900px) {
  .basket-item {
    padding: 3%;
  }

  /* .basket-item-title {
    display: block;
    align-items: center;
  } */

  .basket-item img {
    width: 30%;
    margin: 0 5% 0 0;
  }

  .basket-delete-button {
    padding: 2% 4% 2% 4%;
    font-size: 20px;
  }
}

@media screen and (max-width:900px) {
  .basket-only-small-screen {
    display: block;
    padding: 5%;
    border: 1px solid #ccc;
    border-radius: 10px;
  }
}