.service-card {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px;
  cursor: pointer;
}

.service-card h2 {
  font-size: 21px;
  font-weight: 700;
  position: relative;
  z-index: 1;
  color: white; /* Text color adjusted for better contrast with yellow */
  display: inline-block;
  padding: 0 10px;
}

.service-card p {
  font-size: 17px;
  font-weight: 500;
  margin: 15px 4% 0 4%;
  position: relative;
  z-index: 1;
  color: white; /* Text color adjusted for better contrast with yellow */
  display: inline-block;
  padding: 0 10px;
}

.service-card img {
  display: block;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .service-card {
    margin-top: 4%;
  }
}
