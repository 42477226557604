.banner {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 400px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-content {
  color: #fff;
  text-align: center;
  z-index: 2; /* Make sure the content is above the overlay */
}

.banner-content h1 {
  font-size: 36px;
  margin: 0 5% 20px 5%;
}

.banner-content p {
  font-size: 18px;
  margin: 0 5% 20px 5%;
}