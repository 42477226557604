.home-nav-search-widget-container {
  width: 100%;
}

.home-nav-search-widget {
  display: flex;
  align-items: center;
  position: relative;
}

.home-nav-search-widget #search2 {
  flex: 1;
  padding: 15px;
  resize: none;
  color: black;
  font-size: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(119, 136, 153, 0.2); /* Soft black shadow */
}

.home-nav-search-widget #search2::placeholder {
  color: black;
  font-weight: 700;
  font-family: 'Wix Madefor Display', sans-serif;
}

.home-nav-search-widget label {
  position: absolute;
  right: 10px;
  margin-right: 2%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.home-nav-search-widget .search-icon {
  font-size: 20px;
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1150px) {
  .home-nav-search-widget input {
    width: 70%;
  }

  .home-nav-search-widget-container {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}

.home-nav-search-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  width: 90%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  max-height: 200px;
  overflow-y: auto;
  margin-top: -5px;
}

.home-nav-search-dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.home-nav-search-dropdown-item:hover {
  background-color: #f1f1f1;
}
