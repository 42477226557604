.sticky-bottom-bar {
  display: none;
}

@media screen and (max-width:900px) {
  .sticky-bottom-bar {
    display: flex;
    justify-content: space-between; /* Adjust to evenly distribute items */
    align-items: center; /* Align items vertically */
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px;
    border-top: 1px solid #ccc;
  }

  .sticky-bottom-bar > *:not(:last-child) {
    margin-right: 10px; /* Add margin to separate items */
  }

  .sticky-bottom-bar button {
    flex-grow: 0; /* Prevent the button from growing */
    max-width: 150px; /* Set maximum width for the button */
    margin-left: auto; /* Push button to the right */
    background-color: #083c84;
    color: white;
    border: none;
    padding: 13px 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Wix Madefor Display', sans-serif;
    display: block;
    cursor: pointer;
    border-radius: 10px;
  }

  .sticky-bottom-button:hover {
    background-color: #0056b3;
  }
}
