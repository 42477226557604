.tour-requested-img {
  width: 30%;
  padding: 2%;
  margin: 0 auto;
}

.tour-requested-h2, .tour-requested-p {
  text-align: center;
}

@media (max-width: 900px) {
  .tour-requested-img {
    width: 50%;
    padding: 2%;
  }

  .tour-requested-h2, .tour-requested-p {
    margin-left: 5%;
    margin-right: 5%;
  }
}