/* src/pages/Sitemap.css */
.sitemap {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.sitemap h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #333;
}

.sitemap-list {
  list-style: none;
  padding: 0;
}

.sitemap-list > li {
  margin: 0.5rem 0;
}

.sitemap-list > li > ul {
  list-style: none;
  padding-left: 1.5rem;
}

.sitemap-list a {
  text-decoration: none;
  color: #007bff;
}

.sitemap-list a:hover {
  text-decoration: underline;
}
