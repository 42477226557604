.newsletter-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.newsletter-popup-inner {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative; /* Ensure the close button positions relative to this element */
}

.newsletter-popup-close-btn {
  background: none;
  padding: 0 2% 1% 2%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50px;
  cursor: pointer;
}

.newsletter-popup-close-btn:hover {
  color: #000;
}

.newsletter-popup img {
  width: 20%;
}

.newsletter-popup-inner .newsletter-popup-conditions {
  font-size: 12px;
}

@media (max-width: 900px) {
  .newsletter-popup-inner {
    width: 80%;
    margin: 0 10% 0 10%;
  }

  .newsletter-popup img {
    width: 40%;
  }
}