.tour-container {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2%;
}

.tour-image-container-large-screen {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  max-width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.tour-large-image-header h1 {
  font-size: 1.6rem;
  text-align: left;
}

.tour-image-container-large-screen img {
  max-width: 100%;
  height: auto;
}

.tour-image-container-small-screen {
  display: none;
}

.tour-image-container-small-screen h1 {
  font-size: 1.6rem;
  text-align: left;
}

@media (max-width: 900px) {
  .tour-container {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
  }

  .tour-image-container-large-screen {
    display: none;
  }

  .tour-image-container-small-screen {
    display: block;
  }
}

.tour-left-column {
  float: left;
  width: 70%;
}

.tour-right-column {
  float: left;
  width: 30%;
}

.tour-description {
  padding-right: 20%;
}

@media (max-width: 900px) {
  .tour-left-column {
    width: 100%;
  }

  .tour-right-column {
    display: none;
  }

  .tour-large-image-header {
    display: none;
  }

  .tour-description {
    padding-right: 0%;
  }
}

.tour-quantity-selector {
  background-color: rgb(22, 22, 83);
  color: white;
}

.tour-quantity-selector h2 {
  text-align: center;
}

.tour-header-column-left {
  float: left;
  width: 50%;
}

.tour-header-column-left img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tour-header-column-right {
  float: left;
  width: 50%;
}

.tour-price-box {
  margin-left: 5%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5%;
  border-radius: 10px;
}

.tour-price-box p {
  text-align: center;
}

.tour-truncated-text {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limits to 9 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}