.admin-container {
  margin: 0 auto;
  width: 50%;
}

@media screen and (max-width:900px) {
  .admin-container {
    width: 100%;
    padding: 0 5% 0 5%;
  }
}