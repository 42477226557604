/* Footer */
.footer {
    padding-left: 20%;
    padding-right: 20%;
    background-color: rgb(238, 238, 238);
}

.footer img {
    width: 20%;
    padding: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

@media screen and (max-width:900px) {
    .footer {
        padding-left: 10%;
        padding-right: 10%;
    }

    .footer img {
        width: 40%;
    }
}

.footer-followUsOn {
    text-align: center;
}

.footer-followUsOn h2 {
    text-align: center;
}

.footer-followUsOn ion-icon {
    color: black; 
    font-size: 40px;
    text-align: center;
    padding: 1.5%;
}

.footer-followUsOn ion-icon:hover {
    color: #288eed;
}

.column-footer {
    float: left;
    width: 33.33%;
    padding: 15px;
    margin-bottom: 2%;
    text-align: center;
}

.column-footer h3 {
    font-size: 20px;
    letter-spacing: 0px;
    color: black;
}

.column-footer p {
    font-size: 15px;
    color: black;
    text-align: center;
}

.column-footer a {
    font-size: 15px;
    color: black;
    margin-left: 2%;
}

.column-footer a:hover {
    color: #288eed;
}

.column-footer i {
    color: black;
    font-size: 23px;
    margin-top: 2%;
}

.footer-line {
    width: 100%;
    padding: 20px;
    font-size: 17px;
    background-color: rgb(238, 238, 238);
    color: black;
    text-align: center;
}

.footer-line a {
    color: black;
}

.footer-line a:hover {
    color: #288eed;
}

@media screen and (max-width:900px) {
    .column-footer {
        width: 100%;
    }

    .column-footer a {
        text-align: center;
    }
}
/* END Footer */