.custom-calendar-selector {
  width: 100%; /* Ensure calendar takes full width */
  border-radius: 8px;
  padding: 2%;
}

.custom-calendar-selector .react-calendar__tile {
  padding: 1%;
  background-color: white !important;
}

.custom-calendar-selector .react-calendar__tile abbr {
  padding: 20px;
  border-radius: 50%;
  display: inline-flex; /* Use flexbox to center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  width: 10px; /* Specify a fixed width */
  height: 10px; /* Specify a fixed height to make it a circle */
  text-align: center; /* Ensure text is centered (fallback) */
}

.custom-calendar-selector .react-calendar__tile--active {
  background-color: white !important;
}

.custom-calendar-selector .react-calendar__tile--active abbr {
  background-color: black !important;
  color: white !important;
}

.custom-calendar-selector .selected-date abbr {
  background-color: black;
  color: white;
}

.custom-calendar-selector .selected-date abbr:hover {
  box-shadow: inset 0 0 0 1px black; /* Create an internal border */
}

.custom-calendar-selector .react-calendar__navigation__label__labelText {
  font-size: 20px;
}

.custom-calendar-selector .react-calendar__navigation__next-button,
.custom-calendar-selector .react-calendar__navigation__prev-button {
  font-size: 30px;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}