.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  border-collapse: collapse;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
}

table td, table th {
  border: 1px solid #ddd;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #083c84;
  color: white;
}
