.tickets-container {
  margin: 0 auto;
  width: 70%;
}

.tickets-container h1 {
  text-align: left;
  font-size: 40px;
  color: #2e2e2e;
}

.ticket-column {
  float: left;
  width: 25%;
  padding: 1%;
  margin-bottom: 1%;
}

.tickets-display-small {
  display: none;
}

@media screen and (max-width:900px) {
  .tickets-container h1 {
    font-size: 30px;
  }

  .tickets-display-small {
    display: block;
  }

  .tickets-display-large {
    display: none;
  }

  .tickets-container {
    width: 90%;
  }

  .ticket-column {
    float: left;
    width: 50%;
    padding: 1%;
    margin-bottom: 4%;
  }
}