.trips-container {
  margin: 0 auto;
  width: 70%;
}

.trips-card {
  width: 30%;
  margin: 0 1.66% 0 1.66%;
  float: left;
}

.trips-card {
  margin-bottom: 2%;
  border-radius: 10px;
  color: black;
  cursor: pointer;
}

.trips-card-description {
  padding: 5%;
}

.trips-card-description h3 {
  font-weight: 600;
}

@media screen and (max-width:900px) {
  .trips-card {
    width: 96%;
    margin: 0 2% 5% 2%;
    border-radius: 10px;
    cursor: pointer;
  }
}

/* TRIP BOOK STYLE */
.trip-book-participants {
  display: flex;
}

@media screen and (max-width:900px) {
  .trips-container {
    width: 90%;
  }
}