@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Standard settings for the whole page */
* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  list-style: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: #000000;
}

/* Let the page scrool smoothly when button redirects to div */
html {
  scroll-behavior: smooth;
}

/* Prevent users to scroll page left and right */
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

h1 {
  font-family: "Poppins", sans-serif;
}

.titleheading b {
	letter-spacing: -2px;
  font-weight: 800;
}

@media (min-width: 901px) {
  .titleheading b {
      font-size: 70px;
      font-weight: 800;
  }
}

@media (max-width: 900px) {
  /* If small screen reduce h1 space between letters */
  h1 {
      font-family: "Poppins", sans-serif;
      letter-spacing: -1px;
      font-weight: bold;
  }
}

.thicktitles {
  font-weight: 750;
}

@media (max-width: 900px) {
  .thicktitles {
    letter-spacing: -1px;
  }
}

.button {
  background-color: #2869c4;
  color: white;
  border: none;
  padding: 13px 32px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #007bff;
}

.red-button {
  background-color: red;
  /* Add any other necessary styles */
}

/* Only display 2 lines */
.max-2-lines-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Only display 1 line */
.max-1-line-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* Only display 200px text lenght */
.max-text-width-200 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.space {
  margin: 3%;
}

@media screen and (max-width:900px) {
  .space {
    margin: 10%;
  }
}

.scrollable {
  overflow-x: scroll;
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.popular {
  padding-top: 10px;
  background-image: linear-gradient(rgb(28, 71, 170), white);
  border-radius: 8px;
}

.popular p {
  color: white;
}

/* Services requested box */
.services-requested-container {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
  position: relative;
  margin-top: -5%;
}

.services-requested-container h1 {
  padding: 1.5%;
  color: white;
}

@media screen and (max-width:900px) {
  .services-requested-container {
    margin-left: 0%;
    margin-right: 0%;
  }
}

/* Cards box */
.cards-container {
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
}

.cards-container h1 {
  padding: 1.5%;
  color: black;
}

@media screen and (max-width:900px) {
  .cards-container {
    margin-left: 4%;
    margin-right: 4%;
  }
}

/* Text over image */

.text-over-image-container {
  position: relative;
  text-align: center;
  color: white;
}

.text-over-image-container-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  border-radius: 8px;
  padding: 2%;
}

.text-over-image-container-centered h1 {
  font-size: 60px;
  line-height: 100%;
  text-shadow: 2px 2px 4px #000000;
  letter-spacing: -3px;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width:980px) {
  .text-over-image-container-centered h1 {
    font-family: "Poppins", sans-serif;
    font-size: 43px;
  }
}

.text-over-image-container-centered p {
  font-size: 20px;
  line-height: 150%;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

#image-under-text {
  width: 100%;
}

@media screen and (min-width:980px) {
  #image-under-text {
    height: 44vh;
    object-fit: cover;
    margin-right: 0%;
    margin-left: 0%;
    margin-top: 0%;
  }
}

@media screen and (max-width:979px) {
  #image-under-text {
    height: 25vh;
    object-fit: cover;
    margin-right: 0%;
    margin-left: 0%;
    margin-top: 0%;
  }
}

@media screen and (max-width:979px) {
  .homepage-card-smaller-image {
    height: 20vh;
    object-fit: cover;
    margin-right: 0%;
    margin-left: 0%;
    margin-top: 0%;
  }

  .homepage-card-taller-image {
    height: 30vh;
    object-fit: cover;
    margin-right: 0%;
    margin-left: 0%;
    margin-top: 0%;
  }
}

/* End Text over image */

/* Price Cards */
.column-price-card {
  float: left;
  width: 30%;
  margin: 1.665%;
  margin-bottom: 5%;
  padding-bottom: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
  background-color: white;
  overflow: hidden;
}

.column-price-card-4 {
  float: left;
  width: 22%;
  margin: 1.5%;
  padding-bottom: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 15px;
  background-color: white;
  overflow: hidden;
}

.column-price-card:hover, .column-price-card-4:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.column-price-card:hover img, .column-price-card-4:hover img {
  transform: scale(1.1); /* Increase the scale value for a stronger zoom effect */
  transition: transform 0.3s ease-in-out;
  max-height: 100%;
}

.column-price-card:not(:hover) img, .column-price-card-4:not(:hover) img {
  transform: scale(1); /* Reset the scale back to the original size */
  transition: transform 0.3s ease-in-out;
}

.column-price-card-text-container, .column-price-card-4-text-container {
  padding: 6px 16px;
  height: max-content;
}

/* LOOKS COOL */
.column-price-card .title, .column-price-card-4 .title {
  color: black;
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  margin-top: 3%;
  margin-bottom: 3%;
} 

@media screen and (min-width:901px) {
  .column-price-card .title, .column-price-card-4 .title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 3em; /* Adjust height to control the number of lines */
  } 
}
/* END LOOKS COOL (IF NOT WORKING REMOVE MEDIA SCREEN AND PUT EVERYTHING BACK  TOGETHER */

.column-price-card .description, .column-price-card-4 .description {
  color: gray;
  width: max-content;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
}

.column-price-card .duration, .column-price-card-4 .duration {
  width: max-content;
  font-size: 14px;
  text-align: left;
}

.column-price-card .column-price-card-price, .column-price-card-4 .column-price-card-4-price {
  color: black;
  font-size: 16px;
  text-align: right;
}

.column-price-card .column-price-card-span, .column-price-card-4 .column-price-card-4-span {
  color: rgb(143, 143, 143);
  font-size: 14px;
}

.column-price-card img, .column-price-card-4 img {
  width: 100%;
  cursor: pointer;
  border-radius: 15px 15px 0 0;
}

@media screen and (max-width:900px) {
  .column-price-card {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .column-price-card-4 {
    width: 46%;
    margin-bottom: 5%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .column-price-card-4 .title {
    line-height: 1.5em;
    min-height: 3em;
  }
}
/* End Price Card */

/* Message alerts */
.messagealert {
  width: 30%;
  margin-right: 35%;
  margin-left: 35%;
  margin-top: 2%;
  border-radius: 15px;
  padding: 20px;
  background-color: #27ae60;
  color: white;
  text-align: center;
}

.messagealert-closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.5s;
}

.messagealert-closebtn:hover {
  color: black;
}

@media screen and (max-width:900px) {
  .messagealert {
    width: 80%;
    margin-top: 4%;
    margin-right: 10%;
    margin-left: 10%;
  }
}

.breadcrumbs {
  padding-top: 2%;
  padding-left: 6%;
}

@media screen and (max-width:900px) {
  .breadcrumbs {
    padding-top: 8%;
    padding-left: 8%;
    padding-bottom: 4%;
  }
}

/* Terms and conditions */
.tacbox {
  display: block;
  padding: 1em;
  margin: 2em;
  border: 3px solid #ddd;
  background-color: #eee;
  max-width: 800px;
}

#checkbox {
  height: 2em;
  width: 2em;
  vertical-align: middle;
}
/* END Terms and conditions */

/* Input types bugged on apple */
input[type="date"]
{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
}

input[type="time"]
{
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
}