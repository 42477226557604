.home-nav-container {
  position: relative;
  /* background-image: url('https://api.mylondoncorner.com/images/featured-banner-title.jpg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 520px;
  /* height: 80vh; */
}

.home-nav-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px; /* Adjust the height of the shadow */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.home-nav-search-small {
  display: none; /* Ensure it's displayed */
  position: absolute; /* Absolute positioning to place it halfway between elements */
  top: 100%; /* Position it below the navbar */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Move it halfway up and to the left for perfect centering */
  z-index: 3; /* Ensure it stays above the next div */
  width: 80%; /* Set a width that works well for smaller screens */
}

.home-nav-search-big {
  display: block; /* Ensure it's displayed */
  position: absolute; /* Use absolute positioning */
  top: 100%; /* Position it right below the navbar */
  left: 50%; /* Center it horizontally */
  transform: translate(-50%, -50%); /* Move it halfway up and to the left for perfect centering */
  z-index: 3; /* Ensure it stays above the next div */
  width: 50%; /* Adjust the width for larger screens */
}

.home-nav {
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.home-nav-logo {
  margin: 0.5% 0 0 10%;
  position: relative;
  z-index: 1;
}

.home-nav-logo img {
  width: 80px;
  cursor: pointer;
}

.home-nav-menu {
  list-style: none;
  margin: 0.5% 10% 0 0;
  padding: 0;
  margin-left: auto; /* Align menu items to the right */
  position: relative;
  z-index: 1;
}

.home-nav-menu li {
  display: inline-block;
  margin-left: 20px;
  font-size: 15px;
}

.home-nav-menu li a {
  color: white;
  text-decoration: none;
}

.home-nav-menu li a:hover {
  color: #67b5ff;
}

@media screen and (max-width: 1150px){
  .home-nav-container {
    background-image: url('https://api.mylondoncorner.com/images/featured-banner-title-small.jpg');
    height: 450px;
    position: relative;
  }

  .home-nav-search-small {
    display: block;
  }

  .home-nav-search-big {
    display: none;
  }

  .home-nav-logo {
    margin-left: 0%;
  }

  .home-nav-menu {
    margin-right: 0%;
  }
}

.home-nav-title-overlay {
  position: absolute;
  top: 200px; /* Adjust the top position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for centering */
  color: white; /* Text color */
  z-index: 2; /* Ensure it's above the background image */
  max-width: 40%; /* Set max-width to 40% of the page */
  text-align: center; /* Center the text */
}

.home-nav-title-overlay h1 {
  font-size: 50px; /* Adjust the font size as needed */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  font-weight: 800;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: center; /* Center the heading */
}

.home-nav-title-overlay p {
  font-size: 20px;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  line-height: 30px;
  text-align: center; /* Center the paragraph */
}

@media screen and (max-width: 1150px) {
  .home-nav-title-overlay {
    top: 60%; /* Center vertically */
    bottom: auto; /* Remove the bottom positioning */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both horizontally and vertically */
    max-width: 90%; /* Increase the max-width for better display */
    width: 90%; /* Ensure it takes up more space on smaller screens */
    padding: 0 20px; /* Add some padding to the sides for better readability */
    text-align: center; /* Center the text */
  }

  .home-nav-title-overlay h1 {
    font-size: 35px; /* Adjust the font size as needed */
    line-height: 40px;
    letter-spacing: -1px;
  }

  .home-nav-title-overlay p {
    font-size: 15px;
  }
}

.home-nav-icon-big {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 10px;
}

.home-nav-menu-icon {
  display: none;
  font-size: 40px;
  cursor: pointer;
  color: white;
}

@media screen and (max-width: 749px) {
  .home-nav-menu-icon {
    display: block;
    margin-left: auto;
    margin-right: 10px;
  }
  
  .home-nav-menu {
    display: none;
  }
}

/* Collapsible menu */

.home-nav-collapsible-menu {
  display: none;
}

@media screen and (max-width: 749px) {
  .home-nav-collapsible-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    color: black;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
  }
  
  .home-nav-collapsible-menu ul {
    list-style: none;
    padding: 0;
  }
  
  .home-nav-collapsible-menu li {
    padding: 4% 0 4% 4%;
    margin: 0 5% 0 5%;
    font-size: 15px;
    cursor: pointer;
    color: #2e2e2e;
    border-bottom: 1px solid #e4e4e4;
  }
  
  .home-nav-collapsible-close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 40px;
    cursor: pointer;
    color: #2e2e2e;
  }
  
  .home-nav-collapsible-mlc-icon {
    position: absolute;
    top: 25px;
    left: 20px;
    width: 80px;
  }
}