.booking-conf-container {
  margin: 1% 5% 1% 5%;
}

.booking-conf-row-1-column-left {
  float: left;
  width: 15%;
}

.booking-conf-row-1-column-left img {
  width: 100%;
  padding: 10%;
  margin-top: 40px;
}

.booking-conf-row-1-column-middle {
  float: left;
  width: 50%;
  margin-top: 4%;
  margin-left: 5%;
}

.booking-conf-row-1-column-right {
  float: left;
  width: 25%;
  margin-right: 5%;
  margin-top: 1%;
}

.booking-conf-row-1-column-right img {
  width: 100%;
}

.booking-conf-row-3-column-left {
  float: left;
  width: 49%;
  margin-right: 1%;
}

.booking-conf-row-3-column-left p {
  margin-bottom: 1%;
  font-weight: bold;
}

.booking-conf-row-3-column-right {
  float: left;
  width: 49%;
  margin-left: 1%;
}

.booking-conf-row-3-column-right p {
  font-weight: bold;
}

.booking-conf-row-3-column-right p span {
  color: #288eed;
}

@media screen and (max-width:900px) {
  .booking-conf-row-1-column-left img {
    margin-top: 20px;
  }
}

@media screen and (max-width:515px) {
  .booking-conf-container {
    margin: 1% 5% 1% 5%;
  }

  .booking-conf-row-1-column-left {
    width: 100%;
  }

  .booking-conf-row-1-column-left img {
    width: 50%;
    padding: 10%;
    margin-top: 0px;
  }

  .booking-conf-row-1-column-middle {
    width: 100%;
    margin-top: 0%;
    margin-left: 0%;
  }
  
  .booking-conf-row-1-column-right {
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 5%;
  }
}