.cart-circular-button {
  background-color: #2869c4; /* Button color */
  border: none;
  border-radius: 10px; /* Make it circular */
  width: auto; /* Allow the width to adjust based on content */
  height: 60px; /* Button height */
  padding: 10px 20px; /* Added padding to accommodate the text */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed; /* Fixed position */
  bottom: 30px; /* Distance from the bottom */
  right: 30px; /* Distance from the right */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 998; /* Ensure it's above other elements */
  transition: background-color 0.3s;
  color: white;
  font-size: 18px;
}

.cart-circular-button:hover {
  background-color: #007bff; /* Darker color on hover */
}

.cart-circular-button-cart-icon {
  color: white; /* Icon color */
  font-size: 24px; /* Icon size */
  margin-right: 8px; /* Space between icon and text */
}

@media screen and (max-width: 900px) {
  .cart-circular-button {
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
  }
}