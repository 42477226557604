/* Blog Specific Styles */
.blog-container {
  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .blog-container {
    margin-left: 4%;
    margin-right: 4%;
  }
}

.posts {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.post {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: calc(33.333% - 20px); /* Three posts per row */
  cursor: pointer;
  transition: transform 0.3s;
  margin-top: 3%;
}

@media screen and (max-width: 1200px) {
  .post {
    width: calc(50% - 20px); /* Two posts per row on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .post {
    width: calc(100% - 20px); /* One post per row on small screens */
  }
}

.post-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

.post-content {
  padding: 20px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
}

.post-title {
  font-size: 18px;
  width: 90%;
  text-align: left;
  margin: 2% 0% 0 0%;
  font-weight: bold;
}

.post-category {
  font-size: 16px;
  width: 90%;
  text-align: left;
  margin: 2% 0% 0 0%;
  color: rgb(230, 11, 11);
  font-weight: bold;
}
