.slider-trips-p {
  text-align: left;
  margin-top: 3%;
  font-size: 16px;
}

.slider-trips-h4 {
  text-align: left;
  margin-top: 2%;
  font-size: 17px;
}