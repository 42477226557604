.signup-box {
  text-align: center;
  border-radius: 10px;
  padding: 2.5%;
  margin-left: 25%;
  margin-right:  25%;
  background-color: white;
  text-align: center;
}

@media (max-width: 900px) {
  .signup-box {
    text-align: center;
    border-radius: 10px;
    padding: 2.5%;
    margin-left: 5%;
    margin-right:  5%;
    background-color: white;
    text-align: center;
  }
}

.signup-box .button {
  width: 100%;
  background-color: white;
  color: black;
  border: 2px solid black;
}

.signup-social-logo {
  width: 15px;
}

/* Container for the checkbox and consent text, centered */
.consent-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers horizontally in the container */
  text-align: left; /* Keeps the text alignment to the left */
  width: 100%; /* Ensures the container spans the full width of its parent */
}

/* Hide the default checkbox */
.custom-checkbox input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

/* Custom checkmark styling */
.custom-checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #eee;
  display: inline-block;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  background-color: #4CAF50;
}

.custom-checkbox .checkmark:after {
  content: "";
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #4CAF50;
}

.custom-checkbox .checkmark:after {
  display: block;
}

/* Consent text styling */
.consent-text {
  margin-left: 8px;
  cursor: pointer;
}

/* Privacy policy link styling */
.consent-text a {
  color: #007bff;
  text-decoration: underline;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  &:before {
    content: '';
    background-color: #818078;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: #ffffff;
  }
}