.whatsapp-button {
  background-color: #25D366; /* Button color */
  border: none;
  border-radius: 50%; /* Make it circular */
  width: auto; /* Allow the width to adjust based on content */
  height: 65px; /* Button height */
  padding: 10px 20px; /* Added padding to accommodate the text */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed; /* Fixed position */
  bottom: 30px; /* Distance from the bottom */
  left: 30px; /* Distance from the left */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 998; /* Ensure it's above other elements */
  transition: background-color 0.3s;
  color: white;
  font-size: 20px;
}

.whatsapp-button:hover {
  background-color: #2AE55D; /* Darker color on hover */
}

.whatsapp-button-icon {
  color: white; /* Icon color */
  font-size: 25px; /* Icon size */
}

@media screen and (max-width: 900px) {
  .whatsapp-button {
    bottom: 20px; /* Distance from the bottom */
    left: 20px; /* Distance from the right */
  }
}