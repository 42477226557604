.admin-panel-column-left {
  float: left;
  width: 20%;
  padding: 0 2% 0 2%;
}

.admin-panel-column-right {
  float: left;
  width: 80%;
}

@media screen and (max-width:900px) {
  .admin-panel-column-left {
    float: left;
    width: 100%;
  }
  
  .admin-panel-column-right {
    float: left;
    width: 100%;
  }
}

.admin-panel-h3:hover {
  color: #288eed;
}