.contact-container {
  margin: 0 auto;
  width: 70%;
  text-align: center
}

.contact-container p {
  width: 60%;
  margin: 0 20% 0 20%;
}

.contact-message {
  margin: 0 auto;
  width: 60%;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
}

@media (max-width: 1024px) {
  .contact-container {
    width: 90%;
  }

  .contact-message {
    width: 90%;
  }

  .contact-container p {
    width: 90%;
    margin: 0 5% 0 5%;
  }
}