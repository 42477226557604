.google-reviews {
  text-align: center;
}

.google-reviews-header-stars {
  font-size: 23px;
  color: gold;
}

.google-reviews-header-text {
  font-size: 18px;
}

.google-review-card {
  width: 100%;
  /* border: 1px solid rgb(221, 221, 221); */
  box-shadow: 0px 0px 5px rgb(212, 212, 212);
  border-radius: 15px;
  padding: 2%;
  margin: 4px 0px 60px 4px;
  text-align: center;
}

.google-review-card-user-img {
  border-radius: 50%;
  margin-bottom: 15px;
  width: 20% !important;
}

.google-review-card-gg-img {
  width: 30% !important;
}

.google-review-rating {
  font-size: 30px;
  color: gold;
  margin-bottom: 1px;
  max-width: 100%;
}

.google-review-review {
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .google-review-card {
    padding: 5%;
  }
}
