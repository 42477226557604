.nav-container {
  position: relative;
  height: auto;
  /* border-bottom: 1px solid #ccc; */
}

.nav {
  padding: 20px;
  display: flex;
  align-items: center;
  /* background-color: transparent; */
  background-color: #083c84;
}

.nav-logo {
  margin: 0.5% 0 0 10%;
  position: relative;
  z-index: 1;
}

.nav-logo img {
  width: 80px;
  cursor: pointer;
}

.nav-menu {
  list-style: none;
  margin: 0.5% 0 0 0;
  padding: 0;
  margin-left: auto; /* Align menu items to the right */
  margin-right: 10%;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 749px) {
  .nav-menu {
    display: none;
  }
}

.nav-menu li {
  display: inline-block;
  margin-left: 20px;
  font-size: 15px;
}

.nav-menu li a {
  color: white;
  text-decoration: none;
}

.nav-menu li a:hover {
  color: #67b5ff;
}

@media screen and (max-width: 1150px){
  .nav-logo {
    margin-left: 0%;
  }

  .nav-menu {
    margin-right: 0%;
  }
}

.nav-icon-big {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 10px;
}