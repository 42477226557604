input {
  width: 100%;
  padding: 1%;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  margin-top: 1%;
}

select {
  width: 100%;
  padding: 1%;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  margin-top: 1%;
  /* -webkit-appearance: none; */
  -moz-appearance: none;
  background-color: white;
}

textarea {
  width: 100%;
  padding: 1%;
  border: solid 1px #ccc;
  border-radius: 10px;
  font-size: 15px;
  position: relative;
  resize: none;
  margin-top: 1%;
}