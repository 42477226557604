/* Custom checkmark styling */
.custom-checkbox .checkmark {
  position: relative;
  height: 20px;
  width: 20px;
  background-color: #eee;
  display: inline-block;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  background-color: #4CAF50;
}

.custom-checkbox .checkmark:after {
  content: "";
  display: none;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #4CAF50;
}

.custom-checkbox .checkmark:after {
  display: block;
}