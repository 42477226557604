/* Container styling */
.hp-counter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 5% auto;
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.8);
  width: 80%;
  max-width: 1200px;
}

/* Individual house counter card styling */
.hp-counter-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  width: 30%;
  min-width: 220px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

/* .hp-counter-card:hover {
  transform: scale(1.05);
} */

.hp-counter-image {
  width: 100%;
  max-width: 150px;
  margin-bottom: 15px;
}

.hp-counter-title {
  font-size: 24px;
  color: white;
  margin-bottom: 15px;
}

/* Control buttons and points display */
.hp-counter-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hp-counter-button {
  font-size: 30px;
  color: black;
  padding: 10px;
  background-color: transparent;
  border: 2px solid white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.hp-counter-button:hover {
  background-color: white;
  color: black;
  transform: scale(1.1);
}

.hp-counter-points {
  font-size: 36px;
  color: white;
  margin: 0 15px;
}
