.post-header-container {
  position: relative;
}

.post-header-image {
  width: 100%;
  border-radius: 20px;
}

.post-centered-text {
  font-size: 30px;
  text-align: center;
  line-height: 1.3;
}

@media screen and (max-width:900px) {
  .post-header-image {
    height: 100%;
    display: block;
  }
}

.post-container {
  margin-left: 25%;
  margin-right: 25%;
}

.post-container img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 20px;
}

.post-container p {
  line-height: 1.5rem;
  letter-spacing: 0.2px;
}

.post-container a {
  color: blue;
}

.post-container .a-not-decorated {
  color: black !important;
}

.post-left-column {
  float: left;
  width: 100%;
}

@media screen and (max-width:900px) {
  .post-container {
    margin-left: 5%;
    margin-right: 5%;
  }
}

.post-social-links {
  text-align: center;
}

.post-social-links ion-icon {
  color: rgb(0, 0, 0); 
  font-size: 40px;
  text-align: center;
  padding: 1.5%;
}

.post-correlated {
  border-radius: 10px;
  padding: 2%;
  margin-bottom: 3%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  max-width: 100%; /* Ensures the container doesn't exceed the parent width */
  overflow: hidden; /* Prevents overflow */
  text-align: left;
}

.post-correlated img {
  margin-right: 6%;
  object-fit: cover;
  max-width: 120px; /* Limits the image size */
  height: 120px; /* Maintains aspect ratio */
  border-radius: 10px;
}

.post-correlated h3 {
  flex: 1; /* Allows the text to take up the remaining space */
  overflow: hidden; /* Hides overflowed text */
  text-overflow: ellipsis; /* Adds "..." if the text overflows */
}

/* INSIDE POST CSS FOR TOURS BOOKING */
.post-column-tour-booking-left {
  float: left;
  width: 50%;
}

.post-column-tour-booking-right {
  width: 47%;
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically centers content */
  align-items: center; /* Horizontally centers content */
  height: 100%; /* Takes full height of the parent */
  text-align: left;
  padding-top: 10%; /* Optional: Add space from top */
}

.post-column-tour-booking-right h3 {
  color: #2869c4;
  font-size: 25px;
  margin-left: 6%;
  line-height: 1.8rem;
}

.post-column-tour-booking-right p {
  margin-left: 6%;
}

@media screen and (max-width:1465px) {
  .post-column-tour-booking-left {
    width: 100%;
  }
  
  .post-column-tour-booking-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically centers content */
    align-items: center; /* Horizontally centers content */
    height: 100%; /* Takes full height of the parent */
    text-align: center;
    padding-top: 5%;
    margin-left: 0%;
  }

  .post-column-tour-booking-right h3 {
    margin-left: 0%;
  }

  .post-column-tour-booking-right p {
    margin-left: 0%;
  }
}