.tour-availability-calendar-container {
  width: 90%;
  margin: 0 5% 0 5%;
}

.tour-availability-calendar-column-left {
  float: left;
  width: 48%;
  margin: 0 2% 0 0;
}

.tour-availability-calendar-column-right {
  float: left;
  width: 48%;
  margin: 0 0 0 2%;
}

@media (max-width: 900px) {
  .tour-availability-calendar-column-left {
    float: left;
    width: 98%;
    margin: 0 1% 0 1%;
  }
  
  .tour-availability-calendar-column-right {
    float: left;
    width: 98%;
    margin: 5% 1% 0 1%;
  }
}

.tour-availability-calendar-card {
  width: 100%;
  margin: 0 0 2% 0;
  padding: 30px; /* Adjust padding as needed */
  color: white; /* Text color to contrast with background image */
  background-size: cover; /* Cover the entire card */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent image from repeating */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Distribute space between text and button */
  position: relative; /* Position relative for the pseudo-element */
  border-radius: 8px; /* Optional: adds rounded corners */
  overflow: hidden; /* Ensure content doesn't overflow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add shadow for effect */
}

.tour-availability-calendar-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Place the overlay above the background image */
}

.tour-availability-calendar-card .text-container {
  display: flex;
  flex-direction: column; /* Stack text vertically */
  justify-content: center; /* Center text vertically within its container */
}

.tour-availability-calendar-card .text-container .title {
  margin: 0;
  font-size: 1.2rem; /* Adjust font size as needed */
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Optional: adds shadow to text for better readability */
  position: relative; /* Ensure text is above the overlay */
  z-index: 2; /* Place text above the overlay */
}

.tour-availability-calendar-card .text-container .description {
  margin: 0;
  font-size: 1rem; /* Adjust font size as needed */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Optional: adds shadow to text for better readability */
  position: relative; /* Ensure text is above the overlay */
  z-index: 2; /* Place text above the overlay */
}

.tour-availability-calendar-card .button-container {
  display: flex;
  align-items: center; /* Center button vertically within its container */
  z-index: 2; /* Ensure button is above the overlay */
}

.tour-availability-calendar-card button {
  margin-left: 20px; /* Adjust spacing between text and button as needed */
}
