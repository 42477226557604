.other-products-column-input-1 {
  float: left;
  width: 98%;
  margin-right: 2%;
}

.other-products-column-input-3 {
  float: left;
  width: 31.33%;
  margin-right: 2%;
}

.other-products-column-input-3 input {
  padding: 12px;
  border: 3px solid rgb(52, 196, 39);
}

.other-products-column-input-1 textarea {
  padding: 12px;
  border: 3px solid rgb(52, 196, 39);
}

@media (max-width: 900px) {
  .other-products-column-input-3 {
    width: 100%;
  }
}

.dropdown {
  position: absolute; /* Position it below the input */
  background-color: white; /* Background color of the dropdown */
  border: 1px solid #ccc; /* Border around the dropdown */
  max-height: 200px; /* Max height for the dropdown */
  overflow-y: auto; /* Scroll if too many items */
  z-index: 1000; /* Make sure it appears above other elements */
  width: 100%; /* Match the input width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
}

.dropdown li {
  padding: 10px; /* Padding for dropdown items */
  cursor: pointer; /* Cursor style */
}

.dropdown li:hover {
  background-color: #f0f0f0; /* Change background on hover */
}

/* Default styles for larger screens */
.other-products-column-input-3-icons-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto;
}

.other-products-column-input-3-icons {
  display: flex;
  align-items: flex-start;
  font-size: 35px;
  width: 32%;
}

.other-products-column-input-3-icons-text {
  font-size: 15px;
  margin-left: 10px;
  color: #333;
  flex-grow: 1;
}

/* Styles for small screens */
@media (max-width: 600px) {
  .other-products-column-input-3-icons-row {
    flex-direction: column; /* Stack items vertically */
    width: 100%; /* Full width on small screens */
  }

  .other-products-column-input-3-icons {
    width: 100%; /* Make icons and text take up full width */
    margin-bottom: 15px; /* Add some space between items */
  }

  .other-products-column-input-3-icons-text {
    margin-left: 5%; /* Reset margin for centered text */
    text-align: left; /* Center-align the text */
  }
}